import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { Column, Row } from '../../../components/grid';
import SnippetCard from '../../../components/snippetCard';
import ArrowIcon from '../../../assets/svg/common/arrow-right.inline.svg';
import ShowcaseIcon from '../../../assets/svg/icon/showcase.inline.svg';
import SnippetIcon from '../../../assets/svg/icon/blog.inline.svg';

import * as styles from '../menu.module.css';

interface MenuOverlayProps {
  setMenuOpen: (action: boolean) => void;
}

const Snippets: React.FC<MenuOverlayProps> = ({ setMenuOpen }) => {
  const data: GatsbyTypes.GetMenuSnippetsPreviewsQuery =
    useStaticQuery<GatsbyTypes.GetMenuSnippetsPreviewsQuery>(graphql`
      query GetMenuSnippetsPreviews {
        showcase: allCraftShowcasesShowcaseEntry(limit: 1, sort: { postDate: DESC }) {
          nodes {
            ...SnippetCardFragment
          }
        }
        snippet: allCraftSnippetsBlogEntry(limit: 1, sort: { postDate: DESC }) {
          nodes {
            ...SnippetCardFragment
          }
        }
      }
    `);
  return (
    <Row align="center" className={styles.row} wrap>
      <Column className={styles['column__half']}>
        <Link
          to="/showcase"
          className={`${styles.heading} ${styles['heading__icon']}`}
          onClick={() => setMenuOpen(false)}
        >
          <ShowcaseIcon />
          <span>
            <h1>
              Showcases
              <ArrowIcon className={styles.arrow} />
            </h1>
            <h2>De projecten waar we het meest trots op zijn</h2>
          </span>
        </Link>
        <div className={styles['snippetCard']}>
          {data?.showcase?.nodes[0] && <SnippetCard snippet={data.showcase.nodes[0]} type="list" />}
        </div>
      </Column>
      <Column className={styles['column__half']}>
        <Link
          to="/snippet"
          className={`${styles.heading} ${styles['heading__icon']}`}
          onClick={() => setMenuOpen(false)}
        >
          <SnippetIcon />
          <span>
            <h1>
              Snippets
              <ArrowIcon className={styles.arrow} />
            </h1>
            <h2>Kijk mee bij onze laatste experimenten (en doe mee!)</h2>
          </span>
        </Link>
        <div className={styles['snippetCard']}>
          {data?.snippet?.nodes.map((entry) => {
            return <SnippetCard snippet={entry} key={entry.id} type="list" />;
          })}
        </div>
      </Column>
    </Row>
  );
};

export default Snippets;
