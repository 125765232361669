import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import Details from './components/details';
import Icon from './components/icon';
import Image from './components/image';
import Metadata from './components/metadata';
import Time from '../time';

import * as styles from './snippetCard.module.css';
import withAppContext from '../../containers/appContext/withAppContextHOC';
import { ApplicationState, isExpandedActionTypes } from '../../containers/appContext/AppReducer';

const SnippetCard: SnippetCard = ({
  snippet,
  type = 'card',
  showDate = true,
  dispatch,
}: SnippetCardProps) => {
  function setMenuClose() {
    dispatch({ type: isExpandedActionTypes.COLLAPSE });
  }

  const heroContainer = snippet.heroContainer && snippet.heroContainer[0];

  let imageUrl;
  let imageUrl_2x;

  if (heroContainer?.images) {
    imageUrl = heroContainer.images[0]?.url_card_1x;
    imageUrl_2x = heroContainer.images[0]?.url_card_2x;
  }

  const url =
    snippet.__typename === 'Craft_showcases_showcase_Entry'
      ? `/showcase/${snippet.slug}`
      : `/snippet/${snippet.id}/${snippet.slug}`;

  const classes = classNames({
    [styles.card]: true,
    [styles[`card__${type}`]]: true,
  });

  return (
    <article>
      <Link to={url} className={classes} onClick={setMenuClose}>
        <div className={styles.cardInner}>
          <Details>
            <Icon
              entryType={snippet.__typename}
              snippetType={
                snippet.__typename === 'Craft_snippets_blog_Entry' ? snippet.snippetType : undefined
              }
            />
            <h3 className={styles.title}>{snippet.title}</h3>
            <Metadata>
              <Time time={snippet.postDate} />
              {snippet.dodi?.map((item) => {
                return <div key={item?.id}>{item?.title}</div>;
              })}
            </Metadata>
          </Details>
          <Image url={imageUrl} url_2x={imageUrl_2x} />
        </div>
      </Link>
    </article>
  );
};

export const query = graphql`
  fragment SnippetCardFragment on Craft_EntryInterface {
    __typename
    id: remoteId
    title
    slug
    postDate
    ... on Craft_snippets_blog_Entry {
      snippetType
      heroContainer {
        __typename
        ... on Craft_heroContainer_image_BlockType {
          images {
            ... on Craft_images_Asset {
              url_card_1x
              url_card_2x
            }
          }
        }
        ... on Craft_heroContainer_video_BlockType {
          images: placeholderImage {
            ... on Craft_images_Asset {
              url_card_1x
              url_card_2x
            }
          }
        }
      }
      dodi {
        ... on Craft_dodi_Category {
          __typename
          id
          title
        }
      }
    }
    ... on Craft_showcases_showcase_Entry {
      heroContainer {
        __typename
        ... on Craft_heroContainer_image_BlockType {
          images {
            ... on Craft_images_Asset {
              url_card_1x
              url_card_2x
            }
          }
        }
        ... on Craft_heroContainer_video_BlockType {
          images: placeholderImage {
            ... on Craft_images_Asset {
              url_card_1x
              url_card_2x
            }
          }
        }
      }
      dodi {
        ... on Craft_dodi_Category {
          __typename
          id
          title
        }
      }
    }
  }
`;

interface HeroContainerImage {
  __typename: 'Craft_heroContainer_image_BlockType';
  images: { url: string; url_2x: string }[];
}
interface HeroContainerVideo {
  __typename: 'Craft_heroContainer_video_BlockType';
  images: { url: string; url_2x: string }[];
}

type HeroContainer = HeroContainerImage | HeroContainerVideo;

export interface SnippetCardSnippet {
  __typename: 'Craft_snippets_blog_Entry' | 'Craft_showcases_showcase_Entry';
  id: string;
  title: string;
  slug: string;
  postDate: Date;
  snippetType?: string;
  heroContainer?: [HeroContainer];
  dodi: { id: string; title: string }[];
}

type SnippetCardProps = {
  snippet:
    | GatsbyTypes.SnippetCardFragment_Craft_snippets_blog_Entry_Fragment
    | GatsbyTypes.SnippetCardFragment_Craft_showcases_showcase_Entry_Fragment;
  type?: 'card' | 'list';
  showDate?: boolean;
  state: ApplicationState;
  dispatch: ({ type }: { type: string }) => void;
};

interface SnippetCard {
  ({ snippet }: SnippetCardProps): JSX.Element;
}

export default withAppContext(SnippetCard);
