import React from 'react';
import classNames from 'classnames';

import * as styles from './grid.module.css';

interface GridProps {
  children: React.ReactNode;
  className?: string;
}

interface RowProps extends GridProps {
  align?: 'center' | 'default';
  wrap?: true;
}

interface ColumnProps extends GridProps {
  size?: 'default' | 'small';
}

export const Column: React.FC<ColumnProps> = ({ size, children, className }) => {
  const classes = classNames(className, {
    [styles.column]: true,
    [styles.column__size_default]: size === 'default',
    [styles.column__size_small]: size === 'small',
  });

  return <div className={classes}>{children}</div>;
};

export const Row: React.FC<RowProps> = ({ children, className, align = 'default', wrap }) => {
  const classes = classNames(className, {
    [styles.row]: true,
    [styles['align__center']]: align !== 'default',
    [styles['row__wrap']]: wrap,
  });

  return <div className={classes}>{children}</div>;
};

export const Container: React.FC<GridProps> = ({ children, className }) => {
  const classes = classNames(className, {
    [styles.container]: true,
  });
  return <div className={classes}>{children}</div>;
};
