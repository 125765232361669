import React from 'react';

import * as styles from '../snippetCard.module.css';

interface MetadataProps {
  children: React.ReactNode;
}

const Metadata: React.FC<MetadataProps> = ({ children }) => {
  return (
    <ul className={styles.metadata}>
      {React.Children.map(children, (Child) => {
        return <li>{Child}</li>;
      })}
    </ul>
  );
};

export default Metadata;
