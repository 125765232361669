import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import * as styles from './button.module.css';

interface ButtonPropsBasics {
  children?: React.ReactNode;
  size?: 's' | 'm' | 'l';
  variant?: 'primary' | 'secondary' | 'black' | 'disabled';
}

interface ButtonPropsFake extends ButtonPropsBasics {
  fake: true;
}

interface ButtonPropsButton extends ButtonPropsBasics {
  type: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ButtonPropsInternal extends ButtonPropsBasics {
  replace?: boolean | undefined;
  state?: any;
  to: string;
  rel?: string;
}
interface ButtonPropsExternal extends ButtonPropsBasics {
  newWindow?: boolean;
  href: string;
  rel?: string;
}

type ButtonProps = ButtonPropsInternal | ButtonPropsExternal | ButtonPropsButton | ButtonPropsFake;

const Button: React.FC<ButtonProps> = (props) => {
  const { children, size = 'm', variant = 'primary' } = props;

  const className = classNames({
    [styles.button]: true,
    [styles[`size__${size}`]]: true,
    [styles[`variant__${variant}`]]: true,
  });

  if ('fake' in props) {
    return <span className={className}>{children}</span>;
  }

  if ('to' in props) {
    const { replace, state, to, rel } = props;
    return (
      <Link to={to} state={state} replace={replace} className={className} rel={rel}>
        {children}
      </Link>
    );
  }

  if ('href' in props) {
    const { href, newWindow, rel } = props;
    return (
      <a href={href} target={(newWindow && '_blank') || undefined} className={className} rel={rel}>
        {children}
      </a>
    );
  }

  if ('type' in props) {
    const { type, onClick } = props;
    return (
      // eslint-disable-next-line react/button-has-type
      <button onClick={onClick} type={type} className={className}>
        {children}
      </button>
    );
  }

  return null;
};

export default Button;
