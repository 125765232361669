import React from 'react';

import BlogIcon from '../../../assets/svg/icon/blog.inline.svg';
import ImageIcon from '../../../assets/svg/icon/photo.inline.svg';
import VideoIcon from '../../../assets/svg/icon/video.inline.svg';
import CodeIcon from '../../../assets/svg/icon/code.inline.svg';
import FileIcon from '../../../assets/svg/icon/file.inline.svg';
import ShowcaseIcon from '../../../assets/svg/icon/showcase.inline.svg';

import * as styles from '../snippetCard.module.css';

interface IconProps {
  entryType: string;
  snippetType?: string;
}

const Icon: React.FC<IconProps> = ({ entryType, snippetType }) => {
  if (entryType === 'Craft_showcases_showcase_Entry') {
    return <ShowcaseIcon className={styles.icon} />;
  }

  if (snippetType) {
    switch (snippetType) {
      case 'image':
        return <ImageIcon className={styles.icon} />;
      case 'video':
        return <VideoIcon className={styles.icon} />;
      case 'code':
        return <CodeIcon className={styles.icon} />;
      case 'file':
        return <FileIcon className={styles.icon} />;
      default:
        return <BlogIcon className={styles.icon} />;
    }
  }

  return <BlogIcon className={styles.icon} />;
};

export default Icon;
