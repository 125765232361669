export interface ApplicationState {
  isExpanded: boolean;
  // userMode: string;
}

export const InitialState: ApplicationState = {
  isExpanded: false,
  // userMode: 'default',
};

export interface StateAction {
  type: isExpandedActionTypes;
}

export enum isExpandedActionTypes {
  EXPAND = 'EXPAND',
  COLLAPSE = 'COLLAPSE',
}

export function isExpandedReducer(state = false, action: StateAction): boolean {
  switch (action.type) {
    case isExpandedActionTypes.EXPAND: {
      return true;
    }
    case isExpandedActionTypes.COLLAPSE: {
      return false;
    }
    default:
      return state;
  }
}

/* export const userModeActionTypes = {
  SET_USER_MODE: 'SET_USER_MODE',
};

export function userModeReducer(state = 'default', action: StateAction): string {
  switch (action.type) {
    case userModeActionTypes.SET_USER_MODE: {
      return action.payload;
    }
    default:
      return state;
  }
} */

export default function rootReducer(
  state: ApplicationState,
  action: StateAction
): ApplicationState {
  const { isExpanded } = state;

  return {
    isExpanded: isExpandedReducer(isExpanded, action),
    // userMode: userModeReducer(userMode, action),
  };
}
