import classNames from 'classnames';
import React from 'react';
import { ApplicationState } from '../../containers/appContext/AppReducer';
import withAppContext from '../../containers/appContext/withAppContextHOC';

import * as styles from './appcontainer.module.css';

interface AppContainerProps {
  state: ApplicationState;
  children?: React.ReactNode;
}

const AppContainer: React.FC<AppContainerProps> = ({ state, children }: AppContainerProps) => {
  const classes = classNames({
    [styles.appContainer]: true,
    [styles.appContainer__blocked]: state.isExpanded,
  });
  return <div className={classes}>{children}</div>;
};

export default withAppContext(AppContainer);
