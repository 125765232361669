import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import withAppContext from '../../containers/appContext/withAppContextHOC';
import { ApplicationState, isExpandedActionTypes } from '../../containers/appContext/AppReducer';

import * as styles from './header.module.css';

import NewMenuOverlay from '../../containers/menu';
import Logo from './assets/logo.inline.svg';
import { Container, Row } from '../grid';

/* interface MenuOverlayProps {
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}
const MenuOverlay: React.FC<MenuOverlayProps> = ({ setMenuOpen }) => {
  return (
    <div className={styles['menu-overlay']}>
      <div className={styles['menu-dodi']}>
        <div className={styles['menu-container']}>
          {menuItems.map((item) => {
            const className = classNames({
              [styles.menu__item]: true,
              [styles['menu__item--center']]: item.center,
            });
            return (
              <NavLink
                to={`/dodi/${item.id}`}
                className={className}
                key={item.id}
                style={{ top: `${item.y}%`, left: `${item.x}%` }}
                activeClassName={styles['menu__item--active']}
                onClick={() => setMenuOpen(false)}
              >
                <span>{item.name}</span>
                <MenuBG className={styles['menu__item-bg']} />
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}; */

interface HeaderProps {
  state: ApplicationState;
  dispatch: ({ type }: { type: string; payload?: any }) => void;
}

const Header: React.FC<HeaderProps> = ({ state, dispatch }: HeaderProps) => {
  const menuOpen = state.isExpanded;
  function setMenuOpen(action: boolean) {
    dispatch({ type: action ? isExpandedActionTypes.EXPAND : isExpandedActionTypes.COLLAPSE });
  }

  const hamburgerClassName = classNames({
    [styles.item]: true,
    [styles['item__menu']]: true,
    [styles.active]: menuOpen,
  });

  return (
    <>
      <Container>
        <Row align="center">
          <header className={styles.header}>
            <nav className={styles.menu}>
              <Link to="/" className={styles.logo} title="Home" onClick={() => setMenuOpen(false)}>
                <Logo />
              </Link>
              {/* <Notification /> */}
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                type="button"
                className={hamburgerClassName}
                title="Menu"
              >
                <div className={`${styles.hamburger} ${styles['hamburger__a']}`} />
                <div className={`${styles.hamburger} ${styles['hamburger__b']}`} />
                <div className={`${styles.hamburger} ${styles['hamburger__c']}`} />
              </button>
            </nav>
          </header>
        </Row>
      </Container>
      <NewMenuOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </>
  );
};

export default withAppContext(Header);
