import React from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from './src/containers/appContext';
import AppContainer from './src/components/appContainer';
import Header from './src/components/header';
import Footer from './src/components/footer';
import { Container } from './src/components/grid';
import './src/styles/base.css';
import * as styles from './src/layouts/default.module.css';

export const wrapRootElement = ({ element }) => {
  return (
    <AppContext>
      <AppContainer>
        <Helmet
          htmlAttributes={{
            lang: 'nl-NL',
          }}
        />
        <Header />
        <Container className={styles.main}>
          <main>{element}</main>
          <Footer />
        </Container>
      </AppContainer>
    </AppContext>
  );
};
