import React from 'react';

import TimeIcon from './assets/time.inline.svg';

interface TimeProps {
  time: Date | undefined;
  size?: 'long' | 'short';
}

function getTimeFormat({ time, size }: { time: Date; size: string }): Intl.DateTimeFormatOptions {
  const currentYear = new Date().getFullYear() === time.getFullYear();

  if (size === 'long') {
    return {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
  }

  if (currentYear) {
    return {
      day: '2-digit',
      month: 'short',
    };
  }

  return {
    month: 'short',
    year: 'numeric',
  };
}

const Time: React.FC<TimeProps> = ({ time, size = 'short' }) => {
  if (time === undefined) return null;

  const dateRaw = new Date(time);
  const format = getTimeFormat({ time: dateRaw, size });

  const dateFormatted = new Intl.DateTimeFormat('nl-NL', format).format(dateRaw);

  return (
    <>
      <TimeIcon />
      <time dateTime={dateRaw.toISOString()}>{dateFormatted}</time>
    </>
  );
};

export default Time;
