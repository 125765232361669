import React from 'react';

import * as styles from '../snippetCard.module.css';

interface ImageProps {
  url?: string;
  url_2x?: string;
}

const Image: React.FC<ImageProps> = ({ url, url_2x }) => {
  const srcSet = url && url_2x ? `${url}, ${url_2x} 2x` : undefined;

  return (
    <div className={styles.image}>
      {url && <img src={url} alt="" srcSet={srcSet} />}
      <div className={styles.imageOverlay} />
    </div>
  );
};

export default Image;
