// extracted by mini-css-extract-plugin
export var activities = "menu-module--activities--12eb7";
export var activitiesList = "menu-module--activitiesList--072fd";
export var arrow = "menu-module--arrow--4eb90";
export var column__full = "menu-module--column__full--68236";
export var column__half = "menu-module--column__half--b44e4";
export var container = "menu-module--container--73915";
export var dodiInfo = "menu-module--dodiInfo--4d827";
export var dodiInfo__arrow = "menu-module--dodiInfo__arrow--69ed1";
export var dodiInfo__image = "menu-module--dodiInfo__image--3ae32";
export var dodiInfo__text = "menu-module--dodiInfo__text--6e5eb";
export var heading = "menu-module--heading--6fb13";
export var heading__icon = "menu-module--heading__icon--c85f3";
export var link = "menu-module--link--686fc";
export var link__secondary = "menu-module--link__secondary--9b8ad";
export var list = "menu-module--list--4e316";
export var menu = "menu-module--menu--4c754";
export var menuBg = "menu-module--menuBg--cc62d";
export var row = "menu-module--row--ea87f";
export var snippetCard = "menu-module--snippetCard--c0610";
export var transitionEnter = "menu-module--transitionEnter--085bb";
export var transitionEnterActive = "menu-module--transitionEnterActive--3ed80";
export var transitionExit = "menu-module--transitionExit--5167f";
export var transitionExitActive = "menu-module--transitionExitActive--26d53";