import React from 'react';
import { Link } from 'gatsby';

import withAppContext from '../../containers/appContext/withAppContextHOC';
import { ApplicationState, isExpandedActionTypes } from '../../containers/appContext/AppReducer';

import * as styles from './footer.module.css';
import footerMenuData from './constants/footerMenu.json';
import socialLinksData from './constants/socialLinks.json';

import Twitter from '../../assets/svg/icon/twitter.inline.svg';
import Facebook from '../../assets/svg/icon/facebook.inline.svg';
import Instagram from '../../assets/svg/icon/instagram.inline.svg';
import YouTube from '../../assets/svg/icon/youtube.inline.svg';
import GitHub from '../../assets/svg/icon/github.inline.svg';

import Heart from './assets/heart-icon.inline.svg';
import CKCLogo from './assets/ckc-logo.inline.svg';
import { Row } from '../grid';
import classNames from 'classnames';

interface SocialIconProps {
  icon: 'twitter' | 'instagram' | 'youtube' | 'facebook' | string;
}

const SocialIcon: React.FC<SocialIconProps> = (props) => {
  switch (props.icon) {
    case 'twitter':
      return <Twitter />;
    case 'instagram':
      return <Instagram />;
    case 'youtube':
      return <YouTube />;
    case 'facebook':
      return <Facebook />;
    case 'github':
      return <GitHub />;
    default:
      return <Twitter />;
  }
};

interface FooterMenuProps {
  setMenuOpen: (action: boolean) => void;
}

const FooterMenu: React.FC<FooterMenuProps> = ({ setMenuOpen }) => {
  const { links } = footerMenuData;
  return (
    <ul className={styles['footerMenu']}>
      {links.map((link) => {
        return (
          <li key={link.id}>
            <Link to={link.url} onClick={() => setMenuOpen(false)}>
              {link.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export const SocialLinks: React.FC = () => {
  const { links } = socialLinksData;
  return (
    <ul className={styles.social}>
      {links.map((link) => {
        return (
          <li key={link.id}>
            <a href={link.url} aria-label={link.name}>
              <SocialIcon icon={link.id} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

interface FooterProps {
  state: ApplicationState;
  dispatch: ({ type }: { type: string; payload?: any }) => void;
  inMenu?: boolean;
}

const Footer: React.FC<FooterProps> = ({ dispatch, inMenu = false }) => {
  function setMenuOpen(action: boolean) {
    dispatch({ type: action ? isExpandedActionTypes.EXPAND : isExpandedActionTypes.COLLAPSE });
  }

  const classes = classNames({
    [styles.inMenu]: inMenu,
  });

  return (
    <Row align="center" className={classes}>
      <footer className={styles.footer}>
        <a
          href="https://ckc-zoetermeer.nl"
          className={styles['footerCkc']}
          aria-label="CKC &amp; partners"
        >
          <CKCLogo />
        </a>
        <FooterMenu setMenuOpen={setMenuOpen} />
        <p className={styles.heart}>
          art <Heart aria-label="heart" /> technology
        </p>
        <SocialLinks />
      </footer>
    </Row>
  );
};

export default withAppContext(Footer);
