import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Container } from '../../components/grid';
import Footer from '../../components/footer';
import Activities from './components/activities';
import Snippets from './components/snippets';
import Other from './components/other';

import * as styles from './menu.module.css';

interface MenuOverlayProps {
  menuOpen: boolean;
  setMenuOpen: (action: boolean) => void;
}

const Menu: React.FC<MenuOverlayProps> = ({ menuOpen, setMenuOpen }) => {
  const nodeRef = React.useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={menuOpen}
      timeout={700}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: styles['transitionEnter'],
        enterActive: styles['transitionEnterActive'],
        exit: styles['transitionExit'],
        exitActive: styles['transitionExitActive'],
      }}
    >
      <div className={styles.menu} ref={nodeRef}>
        <div className={styles['menuBg']} />
        <Container className={styles.container}>
          <Activities setMenuOpen={setMenuOpen} />
          <Snippets setMenuOpen={setMenuOpen} />
          <Other setMenuOpen={setMenuOpen} />
          <Footer inMenu={true} />
        </Container>
      </div>
    </CSSTransition>
  );
};

export default Menu;
