// extracted by mini-css-extract-plugin
export var card = "snippetCard-module--card--37262";
export var cardInner = "snippetCard-module--cardInner--bf2d0";
export var card__card = "snippetCard-module--card__card--b5088";
export var card__list = "snippetCard-module--card__list--cd2ef";
export var details = "snippetCard-module--details--18afb";
export var icon = "snippetCard-module--icon--c0e2d";
export var image = "snippetCard-module--image--088a7";
export var imageOverlay = "snippetCard-module--imageOverlay--76218";
export var metadata = "snippetCard-module--metadata--ce557";
export var title = "snippetCard-module--title--1183f";