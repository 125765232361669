import React from 'react';

import * as styles from '../snippetCard.module.css';

interface DetailsProps {
  children: React.ReactNode;
}
const Details: React.FC<DetailsProps> = ({ children }) => {
  return <div className={styles.details}>{children}</div>;
};

export default Details;
