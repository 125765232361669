import React from 'react';
import { Link } from 'gatsby';

import { Column, Row } from '../../../components/grid';
import ArrowIcon from '../../../assets/svg/common/arrow-right.inline.svg';

import * as styles from '../menu.module.css';

interface MenuOverlayProps {
  setMenuOpen: (action: boolean) => void;
}

const Other: React.FC<MenuOverlayProps> = ({ setMenuOpen }) => {
  return (
    <Row align="center" className={styles.row}>
      <Column className={styles['column__full']}>
        <ul className={styles.list}>
          <li className={styles.link}>
            <Link to="/over-het-lab" onClick={() => setMenuOpen(false)}>
              Over het Lab
              <ArrowIcon className={styles.arrow} />
            </Link>
          </li>
          <li className={styles.link}>
            <Link to="/contact" onClick={() => setMenuOpen(false)}>
              Contact
              <ArrowIcon className={styles.arrow} />
            </Link>
          </li>
          <li className={styles.link}>
            <a href="https://docs.digitalartlab.nl">
              Documentatie
              <ArrowIcon className={styles.arrow} />
            </a>
          </li>
        </ul>
      </Column>
    </Row>
  );
};

export default Other;
