import React from 'react';
import { Link } from 'gatsby';

import { Column, Row } from '../../../components/grid';
import ArrowIcon from '../../../assets/svg/common/arrow-right.inline.svg';
import LargeArrowIcon from '../../../assets/svg/common/arrow-right-large.inline.svg';
import dodiImage from '../../../assets/img/menu-dodi@2x.png';

import * as styles from '../menu.module.css';

import data from '../constants/menuItems.json';
import Button from '../../../components/button';

interface MenuOverlayProps {
  setMenuOpen: (action: boolean) => void;
}

const Activities: React.FC<MenuOverlayProps> = ({ setMenuOpen }) => {
  return (
    <Row className={styles.row} wrap>
      <Column className={styles.activities}>
        <h1>Wat we doen</h1>
        <ul className={`${styles['activitiesList']} ${styles.list}`}>
          {data.dodi.map((item) => {
            return (
              <li className={`${styles.link} ${styles.link__secondary}`} key={item.id}>
                <Link to={`/dodi/${item.id}`} onClick={() => setMenuOpen(false)}>
                  {item.name}
                  <ArrowIcon className={styles.arrow} />
                </Link>
              </li>
            );
          })}
        </ul>
      </Column>
      <Column className={styles['dodiInfo']}>
        <img src={dodiImage} alt="Dodi" className={styles['dodiInfo__image']} />
        <LargeArrowIcon className={styles['dodiInfo__arrow']} />
        <Link to="/dodi" onClick={() => setMenuOpen(false)} className={styles['dodiInfo__text']}>
          <p>
            <b>Dit is Dodi.</b> Een samenvatting van alles dat we in het Lab doen. (zoals je ziet:
            best veel!)
          </p>
          <Button fake size="s" variant="black">
            Meer over Dodi
          </Button>
        </Link>
      </Column>
    </Row>
  );
};

export default Activities;
